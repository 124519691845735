import React, { memo } from "react"
import PurpleSolidButton from "../../../components/Buttons/PurpleSolidButton"
const StuckPixelFix = ({ startRepair }) => {
  return (
    <div className="px-3 prose-sm prose sm:prose lg:prose-lg xl:prose-xl md:px-0">
      <p className="lead">
        Click the <code>Repair</code> button and your screen will turn black and
        you will see a square with gradients in it. Drag that square and bring
        it over your stuck pixels and wait like this for 10 - 15 minutes.
      </p>
      <blockquote>
        Do not forget to adjust your power settings so that the screen does not
        turn off.
      </blockquote>
      <p>
        You can press{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        to finish the stuck pixel repair process and exit.
      </p>
      <div className="text-center">
        <PurpleSolidButton text="Repair" onClick={startRepair} />
      </div>
    </div>
  )
}
export default memo(StuckPixelFix)
